<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar novo" :linkItems="linkItems"/>

        <ConfirmModal 
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="cancelRegister"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList"
        />

        <TimedAlert variant="success" message="Departamento salva com sucesso!" :show="savedAlert" />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="departamentoRules">
                    <b-form>
                        <b-row>
                            <b-col lg="6" md="6" sm="12" class="pr-0">
                                <b-form-group label="Nome do Departamento Regional*" label-for="description-input">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Descricao"
                                        rules="required"
                                    >
                                        <b-form-input 
                                            :state="errors.length > 0 || form.errors.errorName ? false:null"
                                            id="description-input" 
                                            v-mask="('X'.repeat(250))"
                                            v-model="form.name"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.errorName" class="text-danger">
                                            {{ form.errors.errorName }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="6" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Estado"
                                    rules="required"
                                >
                                    <b-form-group 
                                        label="Estado*" 
                                        label-for="uf" 
                                        :state="errors.length > 0 || form.errors.errorUf ? false:null">
                                        <v-select 
                                            id="uf"
                                            :reduce="(option => option.uf)"
                                            v-model="form.ufSelected"
                                            :options="estatesSelect"
                                            label="nome"
                                            placeholder="Selecione um Estado"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                             Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.errorUf" class="text-danger">
                                            {{ form.errors.errorUf }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col lg="4" md="6" sm="12" class="pr-0">
                                <b-form-group label="Sigla do Departamento Regional*" label-for="acronym-input">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="acronym"
                                        rules="required"
                                    >
                                        <b-form-input 
                                            :state="errors.length > 0 || form.errors.acronym ? false:null"
                                            id="acronym-input" 
                                            autocomplete="off"
                                            @input="($helpers.CheckAcronymLetters(form.errors, form))"
                                            v-mask="('X'.repeat(15))"
                                            v-model="form.acronym"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.acronym" class="text-danger">
                                            {{ form.errors.acronym }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons mt-3">
                <b-button id="save-departamento" 
                    class="mr-2" 
                    variant="custom-blue"
                    @click.prevent="validationForm(true)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar departamento</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="save-departamento" 
                    class="mr-2" 
                    variant="custom-blue"
                    @click.prevent="validationForm(false)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar e cadastrar um novo</span>
                    <span v-if="savingAndNew" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-departamento" 
                    :disabled="saving || savingAndNew"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import handleErrorsService from './Services/HandleErrors';

export default {
    title: 'Cadastrar departamento',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, 
        BButton, vSelect, ValidationProvider, ValidationObserver,
        TimedAlert, PageHeader, ConfirmModal, SucessoModal
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Departamentos regionais',
                    routeName: 'departamento-list'
                },
                {
                    name: 'Cadastrar novo',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            required,
            savedAlert: false,
            saving: false,
            savingAndNew: false,
            form: {
                name: null,
                ufSelected: null,
                acronym: null,
                errors: {
                    errorName: null,
                    errorUf: null,
                    acronym: null
                }
            },
            estatesSelect: []
        }
    },

    mounted() {
        
        this.$http.get(this.$api.getEstados()).then(({ data }) => {
          
            this.estatesSelect = data;
        });

    },

    methods: {
        validationForm(redirectToList) {
            this.$refs.departamentoRules.validate().then(success => {
                if (success) {
                    redirectToList ? this.saving = true : this.savingAndNew = true;
                    this.saveDepartamento(redirectToList);
                }
            })
        },

        saveDepartamento(redirectToList) {
            const parameters = {
                descricao: this.form.name,
                uf: this.form.ufSelected,
                sigla: this.form.acronym
            };

            this.$http.post(this.$api.departamento(), parameters).then(() => {
                this.saving = false;
                this.savingAndNew = false;
                this.cleanForm();
                
                if(redirectToList) {
                    this.openSuccessModal();
                    return;
                }

                this.savedAlert = !this.savedAlert;
                
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosDepartamento(data, this.form.errors);

                this.saving = false;
                this.savingAndNew = false;
            })
        },

        cleanForm() {
            this.form.name = null;
            this.form.ufSelected = null;
            this.form.acronym = null;

            this.form.errors.errorName = null;
            this.form.errors.errorUf =  null;
            this.form.errors.acronym = null;

            this.$refs.departamentoRules.reset();
        },

        redirectToList() {
            this.$router.push({ name: 'departamento-list' });
        },

        cancelRegister() {
            this.$router.push({ name: 'departamento-list' });
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        openSuccessModal() {
            this.modalSuccess.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        }
    }
}
</script>

